import { ReactNode } from "react";
import PopOverClickTrigger from "./PopOverClickTrigger";
import PopOverHoverTrigger from "./PopOverHoverTrigger";

export type PopOverPositionType =
  | "left-top"
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right"
  | "left-bottom"
  | "top-center";
export interface PopOverProps {
  className?: string;
  wrapperClassName?: string;
  dropdownClassName?: string;
  wrapTriggerClassName?: string;
  children?: ReactNode;
  content?: ReactNode;
  position?: PopOverPositionType;
  trigger?: "click" | "hover";
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onReset?: () => void;
}

const PopOver = (props: PopOverProps) => {
  const { trigger = "click" } = props;
  switch (trigger) {
    case "click":
      return <PopOverClickTrigger {...props} />;
    case "hover":
      return <PopOverHoverTrigger {...props} />;
    default:
      return <PopOverClickTrigger {...props} />;
  }
};

export default PopOver;
