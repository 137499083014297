import { useAuth } from "@/hooks/useAuth";
import { formatDate } from "@/utils/datetime";
import AvatarWithName from "../AvatarWithName";
import Button from "../Button";
import PopOver from "../PopOver";

const PopupAvatarUser = () => {
  const { user, logout } = useAuth();

  return user ? (
    <>
      <PopOver
        trigger="click"
        dropdownClassName="mt-2 !p-3 rounded "
        content={
          <div className=" bg-white rounded flex flex-col items-center justify-center w-[11rem]">
            <AvatarWithName
              imageProps={{ resizeOnFly: false }}
              src={user.avatar ? user.avatar : null}
              name={user.name}
              avatarOnly
              imageClass="!w-16 !h-16"
              className="mb-2 "
              textClass="!text-white font-medium select-none"
            />
            <div className="font-semibold text-fs-14 text-center">
              {user.name}
            </div>
            <div className="text-fs-12 text-grey-500 mb-4">
              Tham gia {formatDate(user.createdDate, "DD/MM/YYYY")}
            </div>
            <Button
              btnType="filled"
              color="secondary"
              type="button"
              onClick={logout}
              className="px-3 py-2 w-full justify-center text-fs-14">
              Đăng xuất
            </Button>
          </div>
        }>
        <div>
          <AvatarWithName
            imageProps={{ resizeOnFly: false }}
            src={user.avatar ? user.avatar : null}
            name={user.name}
            avatarOnly
            textClass="!text-white font-medium select-none"
          />
        </div>
      </PopOver>
    </>
  ) : null;
};

export default PopupAvatarUser;
