import create from 'zustand'
import produce from 'immer'

export type ScrollLockerState = {
	[key: string]: any
	show?: boolean
}

const useScrollLockerStore = create<ScrollLockerState>((set, get) => ({
	lock: (container: string) => {
		set(
			produce((state) => {
				state[container] = {
					show: true,
				}
			})
		)
	},
	unlock: (container: string) => {
		set(
			produce((state) => {
				state[container] = {
					show: false,
				}
			})
		)
	},
}))

export default useScrollLockerStore
