import { MEDIA_URL, STATIC_DOMAIN } from "@/constants/environment";
import { fileType } from "@/constants/file";
import { resizeOnFlyUrl } from "@/utils/image";
import { IMeeyImageProps } from "Models";

const MeeyImage = ({
  src,
  alt,
  className,
  resizeOptions,
  resizeOnFly = true,
  id,
  ssr = true,
  lazyloadClassName = "w-full h-full overflow-hidden",
}: IMeeyImageProps) => {
  const {
    width = 320,
    height = 180,
    resizingType = "fill-down",
    gravity = "ce",
    watermark,
  } = resizeOptions ?? {};

  const imageSrc = resizeOnFly
    ? src.match(fileType.resizeImageSupport)
      ? resizeOnFlyUrl({
          uri: src,
          width,
          height,
          resizingType,
          gravity,
          watermark,
        })
      : MEDIA_URL + src
    : src;
  // console.log(imageSrc);
  return ssr ? (
    <img
      id={id}
      className={className}
      alt={alt}
      width={width ? width : "100%"}
      height={height ? height : "100%"}
      src={imageSrc}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          STATIC_DOMAIN + "/images/default_avatar_project.jpg";
      }}
    />
  ) : (
    <></>
    // <LazyloadImage offset={200} className={lazyloadClassName}>
    // 	<img
    // 		id={id}
    // 		src={imageSrc}
    // 		alt={alt}
    // 		className={className}
    // 		width={width ? width : '100%'}
    // 		height={height ? height : '100%'}
    // 	/>
    // </LazyloadImage>
  );
};

export default MeeyImage;
