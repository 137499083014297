import clsx from "clsx";
import { useEffect, useRef } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";

export const VideoPlayer = (props: {
  options?: any;
  onReady?: any;
  className?: string;
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef<Player>(null);
  // const [playing, setPlaying] = useState(false);
  // const [ready, setReady] = useState(false);
  // const [currentSeek, setCurrentSeek] = useState(0);
  const { options, onReady, className } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...options,
          controlBar: {
            fullscreenToggle: true,
          },
        },
        () => {
          // setReady(true);
          onReady && onReady(player);
        }
      ));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [onReady, options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;
    player.player().on("play", () => {
      // setPlaying(true);
    });
    player.player().on("pause", () => {
      // setPlaying(false);
    });
    player.player().on("timeupdate", () => {
      // setCurrentSeek(player.currentTime());
    });
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      {/* {!playing && currentSeek === 0 ? (
        <MeeyImage
          src={avatar.uri}
          className={classNames(
            "absolute w-full h-full object-cover ",
            playing ? "-z-10 opacity-0" : "z-10 opacity-100"
          )}
        />
      ) : null} */}
      <div className={clsx("w-full h-full  relative embed-video", className)}>
        <div
          className="w-full h-full absolute video-embed flex items-center"
          ref={videoRef}></div>
      </div>
    </>
  );
};

export default VideoPlayer;
