import { isBodyOverflowing } from "./util";
import useScrollLockerStore from "@/store/scroll-locker-store";
import useLayoutEffect from "./hooks/useLayoutEffect";
import { useState } from "react";

export const key = {
  category: "category",
  keyword: "keyword",
  typeOfHouse: "type-of-house",
  location: "location",
  price: "price",
  area: "area",
  project: "project",
  filterMore: "filter-more",
  saveFilter: "save-filter",
  drawer: "drawer",
  favorite: "favorite",
  compare: "compare",
  sort: "sort",
  projectStatus: "project-status",
};

export default function useScrollLocker(
  container: string,
  turnOffLockScreen?: boolean
) {
  const { lock: lockScroll, unlock } = useScrollLockerStore();

  const [opened, setOpened] = useState(false);

  const getContainer = useScrollLockerStore((state) => state)[container] || {};

  const store = useScrollLockerStore((state) => state);

  const onLockBodyScroll = () => {
    const scrollbarSize = window.innerWidth - document.body.offsetWidth;
    const isOverflow = isBodyOverflowing();

    document.body.style.setProperty("overflow", "hidden", "important");

    if (isOverflow) {
      document.body.style.paddingRight = `${scrollbarSize}px`;
    }
  };

  const onUnLockBodyScroll = () => {
    const isOverflow = Object.values(store).some(
      (value) => typeof value !== "function" && value?.show
    );
    if (!isOverflow) {
      document.body.style.overflow = "";
      setTimeout(() => {
        document.body.style.paddingRight = "";
      }, 300);
    } else {
      onLockBodyScroll();
    }
  };

  useLayoutEffect(() => {
    if (!turnOffLockScreen) {
      if (getContainer && getContainer.show) {
        onLockBodyScroll();
      } else {
        onUnLockBodyScroll();
      }

      return () => onUnLockBodyScroll();
    }
  }, [getContainer]);

  const onOpen = () => {
    setOpened(true);
    lockScroll(container);
  };

  const onClose = () => {
    setOpened(false);
    unlock(container);
  };

  return {
    onClose,
    onOpen,
    opened,
  };
}

export const toggleScrollLock = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useScrollLockerStore((state) => state);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { lock: lockScroll, unlock } = useScrollLockerStore();

  const onLockBodyScroll = () => {
    const scrollbarSize = window.innerWidth - document.body.offsetWidth;
    const isOverflow = isBodyOverflowing();

    document.body.style.setProperty("overflow", "hidden", "important");
    if (!isOverflow) {
      document.body.style.paddingRight = `${scrollbarSize}px`;
    }
  };

  const onUnLockBodyScroll = () => {
    const isOverflow = Object.values(store).some(
      (value) => typeof value !== "function" && value?.show
    );
    if (!isOverflow) {
      document.body.style.overflow = "";
      setTimeout(() => {
        document.body.style.paddingRight = "";
      }, 300);
    }
  };

  const disableBodyScroll = (container: string) => {
    if (!store[container] || !store[container].show) {
      lockScroll(container);
      onLockBodyScroll();
    }
  };

  const enableBodyScroll = (container: string) => {
    if (store[container] && store[container].show) {
      unlock(container);
      onUnLockBodyScroll();
    }
  };

  return {
    enableBodyScroll,
    disableBodyScroll,
  };
};

export const lockScroll = () => {
  const scrollbarSize = window.innerWidth - document.body.offsetWidth;
  const isOverflow = isBodyOverflowing();

  // document.body.style.position = "fixed";
  // document.body.style.width = "100vw";

  if (isOverflow) {
    document.body.style.paddingRight = `${scrollbarSize}px`;
    // document.body.style.touchAction = "none";
    document.body.style.overflow = "hidden";
  }
};
export const unlockScroll = () => {
  document.body.style.overflow = "auto";
  document.body.style.paddingRight = `0px`;
  // document.body.style.touchAction = "auto";

  // document.body.style.position = "static";
  // document.body.style.width = "auto";
};
