import { MEEY_REVIEW_API_URL, STATIC_DOMAIN } from "@/constants/environment";
import { DEFAULT_IMAGE_URI } from "@/constants/project-constant";
import { useAuth } from "@/hooks/useAuth";
import { BaseDataListResponse, INotiItem } from "Models";
import axios from "axios";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import AvatarWithName from "../AvatarWithName";
import Link from "next/link";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRelativeTime } from "@/utils/datetime";

const NotiItem = ({
  data,
  updateSeen,
  onClose,
}: {
  data: INotiItem;
  updateSeen?: () => void;
  onClose?: () => void;
}) => {
  const { largeIcon, contents, isSeen, webUrl, updatedAt, _id, type } = data;

  const { token } = useAuth();
  const seenNoti = useCallback(
    async (id) => {
      if (token)
        try {
          await axios.request({
            method: "POST",
            url: "/v1/notifications/seen",
            baseURL: MEEY_REVIEW_API_URL,
            data: {
              _id: id,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          updateSeen && updateSeen();
        } catch (error) {
        } finally {
        }
    },
    [token, updateSeen]
  );

  const getUrlType = (type?: number) => {
    switch (type) {
      case 1:
        return "/images/noti-type/review.svg";
      case 2:
        return "/images/noti-type/question.svg";
      case 3:
        return "/images/noti-type/reply.svg";
    }
  };

  return (
    <div
      className={clsx(
        "flex gap-2 py-3  cursor-pointer  duration-300 px-4 hover:bg-blue-50 ",
        !isSeen ? "bg-[#F6F8FB]" : ""
      )}
      onClick={() => {
        seenNoti(_id);
        onClose && onClose();
      }}>
      <Link href={webUrl} title={webUrl} className="flex gap-2 flex-1">
        <div className="relative flex-0 flex items-center ">
          <div className="relative h-fit">
            <AvatarWithName
              src={
                largeIcon?.uri
                  ? largeIcon?.uri
                  : largeIcon?.url
                  ? largeIcon?.url
                  : DEFAULT_IMAGE_URI
              }
              imageProps={{
                resizeOnFly: largeIcon?.uri ? true : false,
                resizeOptions: {
                  width: 56,
                  height: 56,
                },
              }}
              imageClass="!w-14 !h-14"
              className=" flex-0"
            />
            <div className="absolute bottom-0 right-0">
              <img src={STATIC_DOMAIN + getUrlType(type)} alt="type" />
            </div>
          </div>
        </div>

        <div className="flex-1 text-gray-800">
          <div
            className="line-clamp-3"
            dangerouslySetInnerHTML={{ __html: contents }}></div>
          <div className="text-grey-500 text-fs-12">
            {getRelativeTime(updatedAt)}
          </div>
        </div>
      </Link>

      <div
        className="flex-0 flex items-center"
        onClick={(e) => {
          e.stopPropagation();
          seenNoti(_id);
        }}>
        <div
          className={clsx(
            "w-3 h-3 rounded-full ",
            isSeen ? "bg-transparent" : "bg-blue-500"
          )}></div>
      </div>
    </div>
  );
};
const NotificationPopupContent = ({
  allowBack,
  titleClassName,
  onBack,
  onClose,
}: {
  allowBack?: boolean;
  titleClassName?: string;
  onBack?: () => void;
  onClose?: () => void;
}) => {
  const { token } = useAuth();
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataNoti, setDataNoti] = useState<BaseDataListResponse<INotiItem>>({
    totalResults: 0,
    results: [],
  });

  const getData = useCallback(async () => {
    setLoading(true);
    if (token)
      try {
        const { data } = await axios.request({
          method: "POST",
          url: "/v1/notifications",
          baseURL: MEEY_REVIEW_API_URL,

          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDataNoti({
          ...data,
        });
        setHasMore(data.totalResults > 0);
      } catch (error) {
      } finally {
        setLoading(false);
      }
  }, [token]);

  const getMore = useCallback(async () => {
    if (token)
      try {
        const { data } = await axios.request({
          method: "POST",
          url: "/v1/notifications",
          baseURL: MEEY_REVIEW_API_URL,
          data: {
            lastid: dataNoti.results[dataNoti.results.length - 1]._id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDataNoti({
          ...dataNoti,
          results: [...dataNoti.results, ...data.results],
        });
        setHasMore(data.totalResults > 0);
      } catch (error) {}
  }, [dataNoti, token]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div
        className={clsx(
          "text-fs-24 font-bold px-4 pt-4 pb-2 w-full flex items-center",
          titleClassName
        )}>
        {allowBack ? (
          <i
            className="mrv mrv-arrow_back mr-2 cursor-pointer"
            onClick={onBack}></i>
        ) : null}{" "}
        Thông báo
      </div>

      {loading ? (
        <div className="w-full h-[40rem] flex items-center justify-center">
          <div className={"dot-flashing"}></div>
        </div>
      ) : dataNoti.totalResults ? (
        <div
          className="w-full h-full overflow-auto pb-12 md:pb-0"
          id="scrollableNotiPopup">
          <InfiniteScroll
            dataLength={dataNoti?.results?.length || 0}
            next={getMore}
            scrollableTarget="scrollableNotiPopup"
            hasMore={hasMore}
            loader={
              <div className="px-4 flex items-center justify-center">
                <div className="dot-flashing" />
              </div>
            }>
            {dataNoti?.results.map((e) => (
              <NotiItem
                key={e._id}
                data={e}
                onClose={onClose}
                updateSeen={() => {
                  setDataNoti({
                    ...dataNoti,
                    results: dataNoti.results.map((item) =>
                      item._id === e._id ? { ...item, isSeen: true } : item
                    ),
                  });
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <div className="w-full h-full flex-1 flex flex-col items-center px-4">
          <i className="mrv mrv-notifications text-[100px] text-grey-100 mt-16"></i>
          <div className="text-fs-18 font-semibold">Chưa có thông báo nào!</div>
          <div className="text-center text-fs-14 text-grey-400 mb-32">
            Hãy chia sẻ quan điểm về dự án hoặc tương tác, các thông báo liên
            quan sẽ hiển thị tại đây.
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationPopupContent;
