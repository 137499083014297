import { IMeeyImageProps } from "Models";
import clsx from "clsx";
import { ReactNode, useEffect, useMemo, useState } from "react";
import MeeyImage from "./MeeyImage";
const RANDOM_AVATART_COLOR = [
  {
    background: "#F1F8FF",
    color: "#366FEB",
  },
  {
    background: "#FFF7E1",
    color: "#F3960A",
  },
  {
    background: "#EFFEE9",
    color: "#08A822",
  },
  {
    background: "#F4F2FF",
    color: "#7163CB",
  },
  {
    background: "#FFF0ED",
    color: "#E35B47",
  },
  {
    background: "#ECFFFC",
    color: "#0AB1BC",
  },
  {
    background: "#FDF3E0",
    color: "#865931",
  },
  {
    background: "#FFECFB",
    color: "#830897",
  },
  {
    background: "#FEFFD9",
    color: "#749C02",
  },
  {
    background: "#ECF8FF",
    color: "#005B90",
  },
];
export interface AvatarWithNameProps {
  src: string;
  alt?: string;
  avatarOnly?: boolean;
  name?: ReactNode;
  subTitle?: string;

  textClass?: string;
  subTitleClass?: string;
  imageClass?: string;
  className?: string;
  imageProps?: IMeeyImageProps;
}
const existingUser: {
  name?: string;
  setting?: {
    background: string;
    color: string;
  };
}[] = [];

const AvatarWithName = ({
  src,
  alt,
  name,
  subTitle,
  textClass,
  avatarOnly,
  className,
  subTitleClass,
  imageClass,
  imageProps,
  ...otherProps
}: AvatarWithNameProps) => {
  const [mounted, setMounted] = useState(false);

  const randomItem = useMemo(() => {
    const existing = existingUser.find((item) => item.name === name);
    if (existing) return existing.setting;
    else {
      const setting =
        RANDOM_AVATART_COLOR[
          Math.floor(Math.random() * RANDOM_AVATART_COLOR.length)
        ];
      existingUser.push({
        name: `${name}`,
        setting,
      });
      return setting;
    }
  }, [name]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={clsx("flex items-center ", className)}>
      <div className="flex-[0_0_auto] rounded-full border border-[rgba(0,0,0,0.05)]">
        {src ? (
          <MeeyImage
            className={clsx("rounded-full w-9 h-9 object-cover ", imageClass)}
            src={src}
            resizeOptions={{
              width: 32,
              height: 32,
            }}
            alt={alt || `${name}`}
            {...imageProps}
          />
        ) : mounted ? (
          <div
            className={clsx(
              "rounded-full w-9 h-9 object-cover flex items-center justify-center text-fs-14 ",
              imageClass
            )}
            style={{
              background: randomItem.background,
              color: randomItem.color,
            }}>
            {typeof name === "string" ? name.substring(0, 1) : ""}
          </div>
        ) : null}
      </div>

      {(!avatarOnly && name) || subTitle ? (
        <div className="flex-[1_1_auto] overflow-hidden ml-2">
          <div
            className={clsx(
              "text-gray break-word w-full overflow-hidden",
              textClass
            )}>
            {name}
          </div>
          {subTitle && (
            <div className={clsx("text-gray mt-1 w-full", subTitleClass)}>
              {subTitle}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
export default AvatarWithName;
