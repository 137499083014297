import { MIN_ROUND_NUMBER } from "@/constants/number";
import { removeAlphabets, removeSpecialCharacter } from "./string";

export const roundDown = (num: number, decimal?: number) => {
  const pow = Math.pow(10, decimal ? decimal : 0);
  return Math.floor(num * pow) / pow;
};

export const roundUp = (num: number, decimal?: number) => {
  const pow = Math.pow(10, decimal ? decimal : 0);
  return decimal && decimal > 0 ? Math.round(num * pow) / pow : Math.round(num);
};

export const roundNumber = (input?: number) => {
  if (!input) return null;
  const number = Number(input);
  if (number % MIN_ROUND_NUMBER === 0) return number;
  return Math.round(number);
};
export const isNumeric = (value) => {
  return /^-?\d+$/.test(value);
};
export const castToNumber = (num: any) => {
  if (!num) return 0;
  if (Number.isNaN(Number(num))) return 0;
  return Number(num);
};

export const castToFloat = (
  num: any,
  thousandSeparate?: string,
  decimalSeparate?: string
) => {
  if (!num) return null;

  if (!Number.isNaN(Number(num))) return Number(num);

  const numFormatted = num
    .toString()
    .replace(thousandSeparate ?? ".", " ")
    .replace(decimalSeparate ?? ",", ".");
  if (Number.isNaN(parseFloat(numFormatted))) return null;
  return parseFloat(numFormatted);
};

export const formatNumber = (value: number | string | undefined) => {
  return value
    ? `${removeSpecialCharacter(removeAlphabets(value.toString()))}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )
    : "0";
};

export const randomArrayNumber = (length: number) => {
  return Array.from({ length: length }, () =>
    Math.floor(Math.random() * length)
  );
};
