import { DATE_VI } from "@/constants/date";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import { roundDown } from "./number";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.locale("vi");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);

type DateType = string | number | Date | dayjs.Dayjs | null | undefined;

export const FORMAT_DATE = "DD-MM-YYYY";
export const FORMAT_DATE_VN = "DD/MM/YYYY";

export const getRelativeTime = (date: DateType) => {
  const seconds = Math.abs(dayjs(date).diff(new Date(), "seconds"));

  const minutes = Math.abs(dayjs(date).diff(new Date(), "minutes"));

  if (seconds < 1) {
    return "Vừa xong";
  }

  // (Ngày giờ hiện tại - ngày đăng tin)<60 giây: hiển thị định dạng x giây trước
  if (minutes < 1) {
    return `${seconds} giây trước`;
  }
  const oneHour = 60;
  // (Ngày giờ hiện tại - ngày đăng tin)<60 phút: hiển thị định dạng x phút trước
  if (minutes < oneHour) {
    return `${minutes} phút trước`;
  }

  const oneDay = oneHour * 24;
  // 24 tiếng> (Giờ hiện tại - thời gian tạo) >= 60 phút: hiển thị định dạng x tiếng trước
  if (minutes >= oneHour && minutes < oneDay) {
    return `${roundDown(minutes / oneHour)} tiếng trước`;
  }

  // 30 ngày > (Giờ hiện tại - thời gian tạo) >= 24 tiếng: hiển thị định dạng x ngày trước
  if (minutes >= oneDay && minutes <= 30 * oneDay) {
    return `${roundDown(minutes / oneDay)} ngày trước`;
  }

  return dayjs(date).format(DATE_VI);
};

export const getMinutesByDay = (date: DateType) => {
  return Math.abs(dayjs(date).diff(new Date(), "minutes"));
};

export const subtractDuration = (
  date: DateType,
  value: number,
  unit: dayjs.ManipulateType | undefined
) => dayjs(date).subtract(value, unit);

export const formatDate = (input: any, format?: string) => {
  if (!input) return null;
  return dayjs(input).format(format);
};

export const convertStringToDate = (input: any, format?: string) => {
  if (!input) return null;

  return dayjs(input, format).toDate();
};

export const isPreviousTime = (
  dateOne: DateType,
  dateTwo: DateType,
  unit?: dayjs.OpUnitType | undefined
) => dayjs(dateOne).isBefore(dateTwo, unit);

export const convertSecondToString = (second: number) => {
  if (second < 60) {
    return `Dưới 1 phút`;
  }
  if (second < 3600) {
    return `${Math.floor(second / 60)} phút`;
  }
  if (second < 60 * 60 * 60) {
    return `${Math.floor(second / 3600)} giờ`;
  }
  return `Dưới 1 tuần`;
};

export const initDate = (date: any, format?: string) => dayjs(date, format);
export const getStartOfTime = (date: any, unit: dayjs.OpUnitType) =>
  dayjs(date).startOf(unit);
export const getEndOfTime = (date: any, unit: dayjs.OpUnitType) =>
  dayjs(date).endOf(unit);

export const isPreviousScheduleTimes = (scheduleTimes: number[]) => {
  const sortedTimes = [...scheduleTimes].sort((a, b) => a - b);

  const lastTime = sortedTimes[sortedTimes.length - 1];

  return isPreviousTime(dayjs.unix(lastTime), dayjs(), "day");
};

// cal diff day between unix timestamp input && current day
export const schedulerDateDiff = (timestamp: number, date?: dayjs.Dayjs) => {
  const inputDate = dayjs.unix(timestamp);
  const formattedInputDate = inputDate.format("YYYY-MM-DD");
  const currentFormattedDate = (date ?? dayjs()).format("YYYY-MM-DD");

  const diffDay = dayjs(formattedInputDate).diff(
    dayjs(currentFormattedDate),
    "day"
  );

  return diffDay;
};

export const filterListHourString = (data: any[]) => {
  const currentDate = dayjs().format("YYYY-MM-DD");

  return data.filter((value: any) => {
    return dayjs(`${currentDate} ${value?.use}`).valueOf() > dayjs().valueOf();
  });
};

export const secondToHourString = (
  _seconds: number,
  offset: number = 0,
  type: "string" | "colon" = "colon"
) => {
  const seconds = Math.round(_seconds + offset);
  const hours: number = Math.floor(seconds / 3600);

  const divirsForMinutes: number = seconds % 3600;
  const minutes: number = Math.floor((seconds - hours * 3600) / 60);
  const endSeconds: number = Math.ceil(divirsForMinutes % 60);

  const hourString = hours.toString();
  const minuteString = minutes < 10 ? `0${minutes}` : minutes.toString();
  const secondString =
    Number(endSeconds) < 10 ? `0${endSeconds}` : endSeconds.toString();

  switch (type) {
    case "string":
      return `${hourString}h ${minuteString}m ${secondString}s`;
    case "colon":
      return hours > 0
        ? `${hourString}:${minuteString}:${secondString}`
        : `${minuteString}:${secondString}`;

    default:
      return hours > 0
        ? `${hourString}:${minuteString}:${secondString}`
        : `${minuteString}:${secondString}`;
  }
};
