// @ts-nocheck

import ClickOutside from "@/vendor/click-outside";
import clsx from "clsx";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import CSSTransition from "react-transition-group/CSSTransition";
import { PopOverProps } from ".";

const PopOverClickTrigger = ({
  wrapperClassName,
  className,
  children,
  content,
  dropdownClassName,
  open,
  position = "bottom-left",
  onClose,
  onOpen,
}: PopOverProps) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(open);
  const triggerRef = useRef<HTMLDivElement>();
  const [styleOverlay, setStyleOverlay] = useState<CSSProperties>({
    top: 0,
    left: 0,
  });
  useEffect(() => {
    setIsOpen(!!open);
  }, [open]);

  const handleUpdatePosition = useCallback(() => {
    if (!triggerRef.current) return;
    const bounding = triggerRef.current.getBoundingClientRect();
    if (position === "left-top") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(${-100}%, calc(${-100}% + ${bounding.height}px))`,
      });
    }
    if (position === "top-right") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(calc(${0}% + ${
          bounding.width
        }px), calc(${-100}% + ${bounding.height}px))`,
      });
    }
    if (position === "bottom-left") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(calc(${-100}% + ${
          bounding.width
        }px), calc(${0}% + ${bounding.height}px))`,
      });
    }
    if (position === "bottom-right") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(0, calc(${0}% + ${bounding.height}px))`,
      });
    }
  }, [position]);

  useEffect(() => {
    handleUpdatePosition();
    window.addEventListener("resize", handleUpdatePosition);
    window.addEventListener("scroll", handleUpdatePosition);
    return () => {
      window.removeEventListener("resize", handleUpdatePosition);
      window.removeEventListener("scroll", handleUpdatePosition);
    };
  }, [handleUpdatePosition, isOpen, position]);

  return (
    <ClickOutside
      active={isOpen}
      onClick={() => {
        if (open === undefined) setIsOpen(!isOpen);
        onClose && onClose();
      }}>
      <div className={clsx("meey-popover", wrapperClassName)}>
        <div
          ref={triggerRef}
          className={clsx(
            "text-gray popover-trigger cursor-pointer flex justify-between items-center  rounded",
            className
          )}
          onClick={() => {
            if (open === undefined) setIsOpen(!isOpen);
            if (isOpen) onClose && onClose();
            else onOpen && onOpen();
          }}>
          {children}
        </div>
        <CSSTransition
          in={isOpen}
          nodeRef={nodeRef}
          timeout={200}
          unmountOnExit>
          <div
            style={{
              ...styleOverlay,
            }}
            className={clsx(
              "popover-overlay p-4",
              isOpen ? "popover-overlay-open" : "",
              `popover-overlay-${position}`,
              dropdownClassName
            )}>
            {content}
          </div>
        </CSSTransition>
      </div>
    </ClickOutside>
  );
};

export default PopOverClickTrigger;
