import clsx from "clsx";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { PopOverProps } from ".";

const PopOverHoverTrigger = ({
  className,
  children,
  content,
  dropdownClassName,
  position = "bottom-left",
  open,
  onOpen,
  onClose,
  wrapTriggerClassName,
}: PopOverProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const triggerRef = useRef<HTMLDivElement>();
  const [styleOverlay, setStyleOverlay] = useState<CSSProperties>({
    top: 0,
    left: 0,
  });
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleUpdatePosition = useCallback(() => {
    if (!triggerRef.current) return;
    const bounding = triggerRef.current.getBoundingClientRect();
    if (position === "left-top") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(${-100}%, calc(${-100}% + ${bounding.height}px))`,
      });
    }
    if (position === "left-bottom") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(calc(${-100}% - 12px ), calc(${0}% + ${0}px))`,
      });
    }
    if (position === "top-left") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(calc(${0}%  ), calc(${-50}% - ${
          bounding.height
        }px))`,
      });
    }
    if (position === "top-right") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(calc(${0}% + ${
          bounding.width
        }px), calc(${-100}% + ${bounding.height}px))`,
      });
    }
    if (position === "bottom-left") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(calc(${-100}% + ${
          bounding.width
        }px), calc(${0}% + ${bounding.height}px))`,
      });
    }
    if (position === "bottom-right") {
      setStyleOverlay({
        top: bounding.top,
        left: bounding.left,
        transform: `translate(0, calc(${0}% + ${bounding.height + 20}px))`,
      });
    }
  }, [position]);

  useEffect(() => {
    handleUpdatePosition();
    window.addEventListener("resize", handleUpdatePosition);
    window.addEventListener("scroll", handleUpdatePosition);
    return () => {
      window.removeEventListener("resize", handleUpdatePosition);
      window.removeEventListener("scroll", handleUpdatePosition);
    };
  }, [handleUpdatePosition, isOpen, position]);
  return (
    <div
      className={clsx("meey-popover", className)}
      onMouseOver={() => {
        setIsOpen(true);
        onOpen && onOpen();
      }}
      onMouseOut={() => {
        setIsOpen(false);
        onClose && onClose();
      }}>
      <div
        className={clsx("popover-trigger", wrapTriggerClassName)}
        onClick={onOpen}
        ref={triggerRef}>
        {children}
      </div>
      {/* <CSSTransition
					in={open}
					nodeRef={nodeRef}
					timeout={200}
					unmountOnExit
					onEnter={() => {}}
					onExited={() => {}}
				> */}
      {isOpen && content ? (
        <div
          style={{
            ...styleOverlay,
          }}
          className={clsx(
            "popover-overlay",
            isOpen ? "popover-overlay-open" : "",
            `popover-overlay-${position}`,
            dropdownClassName
          )}>
          {content}
        </div>
      ) : null}
      {/* </CSSTransition> */}
    </div>
  );
};

export default PopOverHoverTrigger;
